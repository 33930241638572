<template>
  <main id="quiz">
    <section class="content">
      <section class="intro">
        <aside class="back" @click="back">
          tillbaka
        </aside>
        <img src="@/assets/icon-quiz.svg" alt="quiz">
      </section>
      <article class="floating">
        <h1>Vad påverkar mitt gymnasieval</h1>
        <p class="ingress">Det är många faktorer som spelar in när du ska välja gymnasieprogram. Vilka tycker du påverar dig lite extra?</p> 
        <p>Gradera följande faktorer mellan 1 och 5, där 1 är "påverkar inte alls" och 5 är "påverkar jättemycket". Testet tar ca 5 minuter.</p>
        <a href="#" class="btn" @click="startTest">Då kör vi!</a>
      </article>
    </section>
  </main>
</template>

<script>
export default {
  name: 'quiz',
  methods: {
    startTest(){
      this.$emit('move', { transitionType: 'start', to: '/test/valpaverkan/fragor' });
    },
    back(){
      this.$emit('move', { transitionType: 'reveal', to: '/test' });
    }
  }
}
</script>

<style lang="scss">
@import '../../scss/variables';

#quiz {
    
    .content {

        .intro {
            position: relative;

            aside {
                background: black;
                position: absolute;
                top: 1rem;
                left: 1rem;
                padding: .25rem .5rem;
                border-radius: $radius;
            }
            
            img {
              max-width: 12rem;
            }
        }
    }
}

</style>

